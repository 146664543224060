import { t } from "@lingui/macro"
import { gettext } from "@multimediallc/web-utils"
import { featureFlagIsActive } from "../../../hooks/appContext"
import { mergeClasses } from "../../../utils/css"
import { Button, ButtonColor } from "../../common"
import "./SendButton.scss"

interface SendButtonProps {
    onClick: () => void
    isPm: boolean
    variant: SendButtonVariant
    /**
     * Sets the button's display to `none`
     */
    hidden?: boolean
    /**
     * Turns the button color from grey to orange, mobile variant only
     */
    highlight?: boolean
}

export const i18n = {
    sendCAPS: featureFlagIsActive("LinguiJSReact") ? t`SEND` : gettext("SEND"),
}

export const enum SendButtonVariant {
    SplitMode = "SplitMode",
    DraggableCanvas = "DraggableCanvas",
    MobileSplitMode = "MobileSplitMode",
}

export function SendButton({
    onClick,
    isPm,
    variant,
    hidden,
    highlight,
}: SendButtonProps) {
    const dataAttributes = {
        "data-paction":
            variant === SendButtonVariant.SplitMode ? "Chat" : undefined,
        "data-pactionname":
            variant === SendButtonVariant.SplitMode ? "Send" : undefined,
    }

    const isMobileSplitMode = variant === SendButtonVariant.MobileSplitMode
    const text = isMobileSplitMode ? "" : i18n.sendCAPS
    const color = isMobileSplitMode ? ButtonColor.Clear : ButtonColor.Red
    const highlightClass =
        isMobileSplitMode && highlight ? "highlight" : undefined

    return (
        <Button
            onClick={onClick}
            text={text}
            title={i18n.sendCAPS}
            color={color}
            className={mergeClasses(
                "SendButton",
                variant,
                isPm ? "pm" : "chat", // for nightwatch
                hidden ? "hidden" : undefined,
                highlightClass,
            )}
            {...dataAttributes}
            data-testid="send-button"
        />
    )
}
