import type { KeyboardEventHandler } from "react"
import { useEffect, useRef, useState } from "react"
import { gettext } from "@multimediallc/web-utils"
import { useDebounce } from "../../../hooks/useDebounce"
import { error } from "../../../utils/debug"
import { Search } from "../../common/atoms/Icons/Others"
import { LanguageFilterSearchAutocomplete } from "../LanguageFilterSearchAutocomplete"
import { searchSpokenLanguages } from "../LanguageFilterSection"
import type { ILanguage } from "../LanguageFilterSection"
import "./LanguageFilterSearchInput.scss"

interface LanguageFilterSearchInputProps {
    onLanguageClick: (value: string) => void
}

const i18n = {
    searchLanguages: gettext("Search languages"),
}
const MAX_INPUT_LENGTH = 24

export function LanguageFilterSearchInput({
    onLanguageClick,
}: LanguageFilterSearchInputProps) {
    const [searchInputValue, setSearchInputValue] = useState<string>("")
    const debouncedSearchValue = useDebounce<string>(searchInputValue, 100)
    const [showAutoCompleteElement, setShowAutoCompleteElement] =
        useState<boolean>(false)
    const [selectedItemIndex, setSelectedItemIndex] = useState<
        number | undefined
    >(undefined)
    const [autocompleteItems, setAutocompleteItems] = useState<ILanguage[]>([])
    const searchInputElement = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!showAutoCompleteElement) {
            return
        }

        searchSpokenLanguages({ search: debouncedSearchValue })
            .then((languages) => {
                setAutocompleteItems(
                    languages.toSorted((a, b) =>
                        a.display_name.localeCompare(b.display_name),
                    ),
                )
            })
            .catch((e) => error(e))
    }, [showAutoCompleteElement, debouncedSearchValue])

    const handleLanguageClick = (language: string) => {
        onLanguageClick(language)
        if (searchInputElement.current) {
            searchInputElement.current.value = ""
        }
        setSearchInputValue("")
    }

    const arrowSelectIndex = (index: number) => {
        setSelectedItemIndex(index)
        if (searchInputElement.current) {
            searchInputElement.current.value =
                autocompleteItems[index].display_name
        }
    }

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
        event,
    ): void => {
        if (autocompleteItems.length > 0) {
            if (event.key === "ArrowDown") {
                event.preventDefault() // Prevent the cursor from moving
                arrowSelectIndex(
                    selectedItemIndex === undefined
                        ? 0
                        : (selectedItemIndex + 1) % autocompleteItems.length,
                )
            } else if (event.key === "ArrowUp") {
                event.preventDefault()
                arrowSelectIndex(
                    selectedItemIndex === undefined || selectedItemIndex - 1 < 0
                        ? autocompleteItems.length - 1
                        : selectedItemIndex - 1,
                )
            } else if (event.key === "Enter") {
                event.preventDefault()
                if (selectedItemIndex !== undefined) {
                    handleLanguageClick(
                        autocompleteItems[selectedItemIndex].code,
                    )
                    setShowAutoCompleteElement(false)
                    searchInputElement.current?.blur()
                }
            }
        }
    }

    return (
        <div className="LanguageFilterSearchInput">
            <div className="searchButton">
                <Search className="searchIcon" />
            </div>
            <input
                className="languageSearchInput"
                placeholder={i18n.searchLanguages}
                maxLength={MAX_INPUT_LENGTH}
                ref={searchInputElement}
                onInput={() => {
                    setSearchInputValue(searchInputElement.current!.value)
                }}
                onBlur={() => setShowAutoCompleteElement(false)}
                onFocus={() => setShowAutoCompleteElement(true)}
                onKeyDown={(e) => handleKeyDown(e)}
            />
            {showAutoCompleteElement && (
                <LanguageFilterSearchAutocomplete
                    items={autocompleteItems}
                    selectedItemIndex={selectedItemIndex}
                    setSelectedItemIndex={setSelectedItemIndex}
                    onLanguageClick={handleLanguageClick}
                />
            )}
        </div>
    )
}
