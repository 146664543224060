import type React from "react"
import { type ComponentPropsWithoutRef, useEffect, useState } from "react"
import "./ModalOption.scss"

interface ModalOptionProps extends ComponentPropsWithoutRef<"div"> {
    callback: () => void
    title: string
    description: string
    selected: boolean
    tooltip?: string
    tabHandle?: React.RefObject<HTMLDivElement>
}

export function ModalOption({
    callback,
    title,
    description,
    selected,
    tooltip = "",
    tabHandle,
    ...props
}: ModalOptionProps) {
    const [isSelected, setIsSelected] = useState<boolean>(false)
    useEffect(() => {
        setIsSelected(selected)
    }, [selected])
    const clickHandler = () => {
        if (isSelected) {
            return
        }
        setIsSelected(true)
        callback()
    }
    const filled = selected ? (
        <path
            className="radioFill"
            d="M9.10015 6.00039C9.10015 7.71247 7.71223 9.10039 6.00015 9.10039C4.28806 9.10039 2.90015 7.71247 2.90015 6.00039C2.90015 4.28831 4.28806 2.90039 6.00015 2.90039C7.71223 2.90039 9.10015 4.28831 9.10015 6.00039Z"
        />
    ) : (
        ""
    )
    const iconDiv = (
        <svg
            className="optionIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
        >
            <circle className="radioStroke" cx="6" cy="6" r="5.5" />
            {filled}
        </svg>
    )
    const tooltipDiv =
        tooltip !== "" ? (
            <div className="optionToolTip">
                <svg
                    className="checkIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                >
                    <path
                        d="M4.15048 10.3649C4.56778 10.7866 5.24501 10.7978 5.67607 10.3903L11.8877 4.53219C12.1826 4.26687 12.3108 3.86248 12.2226 3.47554C12.1344 3.0886 11.8437 2.77983 11.463 2.66874C11.0823 2.55766 10.6714 2.66169 10.3892 2.94057L4.95306 8.06719L3.63205 6.73282C3.35896 6.44749 2.95335 6.33174 2.57098 6.43004C2.18861 6.52834 1.88894 6.82539 1.78705 7.20712C1.68516 7.58885 1.7969 7.9959 2.07935 8.27194L4.15048 10.3649Z"
                        fill="#009900"
                    />
                </svg>
                <div className="tooltipDesc">{tooltip}</div>
            </div>
        ) : null
    const className = ["modalOption", selected ? "selected" : ""].join(" ")
    const descriptionDiv = (
        <div className="disciptionDiv">
            <div className="tokenIcon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                >
                    <g clipPath="url(#clip0_691_6968)">
                        <path
                            d="M4.45365 10.5489C4.48852 10.5476 4.52153 10.5328 4.54569 10.5077C4.56985 10.4825 4.58327 10.4489 4.58311 10.414V9.14598C4.58313 9.12768 4.57944 9.10957 4.57226 9.09274C4.56509 9.07591 4.55458 9.06071 4.54136 9.04806C4.52815 9.0354 4.51251 9.02555 4.49539 9.01911C4.47826 9.01267 4.46001 9.00976 4.44173 9.01056C4.22507 9.02031 4.0084 9.02519 3.79173 9.02519C2.65278 9.05374 1.51723 8.88891 0.433402 8.53769C0.41271 8.5298 0.3904 8.52711 0.368426 8.52985C0.346453 8.53259 0.325486 8.54067 0.307363 8.5534C0.289239 8.56612 0.274513 8.58309 0.264473 8.60283C0.254434 8.62257 0.249388 8.64446 0.249777 8.6666V9.83281C0.249777 10.2374 1.83523 10.5624 3.79173 10.5624C4.01815 10.5624 4.23879 10.5579 4.45365 10.5489Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M3.79171 4.15033C4.97754 4.21094 6.16217 4.00992 7.26163 3.56154C7.28342 3.55007 7.30166 3.53284 7.31436 3.51174C7.32706 3.49064 7.33374 3.46646 7.33367 3.44183V2.16675C7.33368 2.1447 7.32832 2.12299 7.31803 2.10349C7.30774 2.08399 7.29285 2.0673 7.27465 2.05486C7.25645 2.04242 7.23549 2.03462 7.21358 2.03212C7.19168 2.02962 7.1695 2.03252 7.14896 2.04054C6.06514 2.39176 4.92959 2.55659 3.79063 2.52804C2.65167 2.55659 1.51613 2.39176 0.432297 2.04054C0.411879 2.03296 0.389934 2.03042 0.368323 2.03313C0.346712 2.03584 0.326074 2.04372 0.308159 2.05611C0.290244 2.06849 0.275581 2.08502 0.265414 2.10428C0.255247 2.12354 0.249875 2.14497 0.249756 2.16675V3.44237C0.249788 3.46691 0.256514 3.49097 0.269208 3.51197C0.281903 3.53297 0.300085 3.55011 0.321798 3.56154C1.42125 4.00992 2.60589 4.21094 3.79171 4.15033Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M3.79171 1.98352C4.97754 2.04412 6.16217 1.84311 7.26163 1.39473C7.28342 1.38325 7.30166 1.36603 7.31436 1.34493C7.32706 1.32383 7.33374 1.29965 7.33367 1.27502V1.04698C7.33367 0.643436 5.74821 0.315186 3.79171 0.315186C1.83521 0.315186 0.249756 0.643436 0.249756 1.04698V1.27556C0.249788 1.3001 0.256514 1.32416 0.269208 1.34516C0.281903 1.36616 0.300085 1.3833 0.321798 1.39473C1.42125 1.84311 2.60589 2.04412 3.79171 1.98352Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M3.79171 8.48346C4.02301 8.48346 4.24346 8.4775 4.45417 8.46775C4.48895 8.46608 4.52175 8.45108 4.54575 8.42587C4.56976 8.40065 4.58313 8.36715 4.58309 8.33234V7.5025C4.57791 7.33913 4.60459 7.17631 4.66163 7.02313C4.66995 7.00209 4.67282 6.9793 4.66998 6.95686C4.66714 6.93441 4.65868 6.91305 4.64538 6.89475C4.63185 6.87678 4.61409 6.86244 4.59368 6.85299C4.57327 6.84354 4.55084 6.83928 4.52838 6.84059C4.28301 6.85359 4.03655 6.86063 3.79171 6.86063C2.65276 6.88918 1.51721 6.72435 0.433381 6.37313C0.412862 6.36531 0.390749 6.3626 0.36895 6.36523C0.34715 6.36786 0.326317 6.37576 0.308246 6.38823C0.290175 6.4007 0.275409 6.41738 0.265219 6.43683C0.25503 6.45629 0.249723 6.47792 0.249756 6.49988V7.77767C0.249788 7.80221 0.256514 7.82627 0.269209 7.84727C0.281903 7.86827 0.300086 7.88541 0.321798 7.89684C1.42142 8.34448 2.60605 8.54475 3.79171 8.48346Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M3.79171 6.31684C4.9708 6.30144 6.14033 6.10281 7.25838 5.72804C7.28024 5.7166 7.29855 5.69939 7.31134 5.6783C7.32413 5.6572 7.33092 5.63301 7.33096 5.60834V4.33325C7.33098 4.31121 7.32561 4.28949 7.31532 4.26999C7.30504 4.25049 7.29015 4.2338 7.27194 4.22136C7.25374 4.20892 7.23278 4.20112 7.21088 4.19862C7.18897 4.19613 7.16679 4.19902 7.14626 4.20704C6.06243 4.55826 4.92688 4.7231 3.78792 4.69454C2.64897 4.7231 1.51342 4.55826 0.429589 4.20704C0.409321 4.2 0.387671 4.19788 0.366422 4.20085C0.345173 4.20382 0.324933 4.2118 0.307371 4.22412C0.289809 4.23645 0.275427 4.25277 0.26541 4.27174C0.255393 4.29072 0.250027 4.3118 0.249756 4.33325V5.61104C0.249788 5.63558 0.256514 5.65964 0.269208 5.68064C0.281903 5.70164 0.300085 5.71878 0.321798 5.73021C1.42142 6.17785 2.60605 6.37813 3.79171 6.31684Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M5.84999 8.49391C5.82946 8.48588 5.80728 8.48299 5.78537 8.48549C5.76347 8.48798 5.74251 8.49579 5.7243 8.50823C5.7061 8.52066 5.69121 8.53736 5.68093 8.55686C5.67064 8.57636 5.66527 8.59807 5.66528 8.62012V9.89737C5.66522 9.922 5.6719 9.94617 5.6846 9.96728C5.6973 9.98838 5.71553 10.0056 5.73733 10.0171C6.83712 10.4656 8.02214 10.6666 9.20833 10.6059C10.3941 10.6665 11.5788 10.4655 12.6782 10.0171C12.7 10.0056 12.7182 9.98832 12.7309 9.96723C12.7436 9.94614 12.7503 9.92198 12.7503 9.89737V8.62012C12.7503 8.59807 12.7449 8.57636 12.7346 8.55686C12.7244 8.53736 12.7095 8.52066 12.6913 8.50823C12.6731 8.49579 12.6521 8.48798 12.6302 8.48549C12.6083 8.48299 12.5861 8.48588 12.5656 8.49391C11.4817 8.84513 10.3462 9.00996 9.20724 8.98141C8.06865 9.00984 6.93348 8.845 5.84999 8.49391Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M9.20832 11.1475C8.06937 11.1761 6.93382 11.0113 5.84999 10.66C5.82946 10.652 5.80728 10.6491 5.78537 10.6516C5.76347 10.6541 5.74251 10.6619 5.7243 10.6744C5.7061 10.6868 5.69121 10.7035 5.68093 10.723C5.67064 10.7425 5.66527 10.7642 5.66528 10.7863V11.9525C5.66528 12.3571 7.25074 12.6843 9.20724 12.6843C11.1637 12.6843 12.7492 12.3593 12.7492 11.9525V10.7868C12.7492 10.7648 12.7438 10.743 12.7336 10.7235C12.7233 10.704 12.7084 10.6873 12.6902 10.6749C12.672 10.6625 12.651 10.6547 12.6291 10.6522C12.6072 10.6497 12.585 10.6526 12.5645 10.6606C11.4813 11.0114 10.3465 11.176 9.20832 11.1475Z"
                            fill="#0C6A93"
                        />
                        <path
                            d="M5.6665 7.50254V7.73059C5.66644 7.75522 5.67312 7.77939 5.68582 7.8005C5.69852 7.8216 5.71675 7.83882 5.73855 7.85029C6.838 8.29868 8.02264 8.49969 9.20846 8.43909C10.3943 8.49969 11.5789 8.29868 12.6784 7.85029C12.7002 7.83882 12.7184 7.8216 12.7311 7.8005C12.7438 7.77939 12.7505 7.75522 12.7504 7.73059V7.50254C12.7504 7.099 11.165 6.77075 9.20846 6.77075C7.25196 6.77075 5.6665 7.099 5.6665 7.50254Z"
                            fill="#0C6A93"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_691_6968">
                            <rect width="13" height="13" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className="optionDescription">{description}</div>
        </div>
    )
    return (
        <div
            {...props}
            className={className}
            onClick={clickHandler}
            ref={tabHandle}
        >
            {iconDiv}
            <div className="details">
                <div className="optionTitle">{title}</div>
                <div className="detailsContainer">
                    {descriptionDiv}
                    {tooltipDiv}
                </div>
            </div>
        </div>
    )
}
