import { useEffect, useState } from "react"
import { gettext, interpolate, ngettext } from "@multimediallc/web-utils"
import { IUserInfo } from "../../../../interfaces/userMenu"
import { colorClass } from "../../../../utils/css"
import { UserList } from "../UserList/UserList"
import "./UserListContent.scss"

// Since the parent is in TS and isn't a React component, we cannot utilize React hooks
// like forwardRef and useImperativeHandle to mimic public class methods. Therefore,
// here we use useEffect, which in turn require props (with names prefixed with "id")
// that we deliberately reassign the value of, to trigger the desired effect.
// ** idShowUsers: hides loading UI once props.roomUsers has been fetched
type UserListContentProps = {
    roomUsers: IUserInfo[]
    createContextMenu: (user: IUserInfo, element: HTMLElement) => void
    refreshFunc: () => void
    anonCount: number
    idShowUsers?: number
}

export const i18n = {
    loadingUserListText: gettext("Loading user list..."),
    refreshUserListText: gettext("refresh userlist"),
    anonUsersCount: (anonCount: number): string => {
        return interpolate(
            ngettext(
                "+%(anonCount)s anonymous user",
                "+%(anonCount)s anonymous users",
                anonCount,
            ),
            { anonCount: anonCount },
            true,
        )
    },
}

export function UserListContent(props: UserListContentProps) {
    const [loading, setLoading] = useState(false)

    // Hide loading UI once data has been fetched
    useEffect(() => {
        setLoading(false)
    }, [props.roomUsers, props.idShowUsers])

    return (
        <div className={`UserListContent ${colorClass.defaultColor}`}>
            <div className={`loadingDiv ${!loading ? "hidden" : ""}`}>
                {i18n.loadingUserListText}
            </div>
            <div className={`contentDiv ${loading ? "hidden" : ""}`}>
                <div className="refreshDiv">
                    <span
                        className="refreshLink"
                        onClick={() => {
                            setLoading(true)
                            props.refreshFunc()
                        }}
                    >
                        {i18n.refreshUserListText}
                    </span>
                </div>
                <div className="userListDiv">
                    <UserList
                        roomUsers={props.roomUsers}
                        createContextMenu={props.createContextMenu}
                    />
                </div>
                <div
                    className={`anonUserCount ${colorClass.defaultTooltipColor}`}
                >
                    {i18n.anonUsersCount(props.anonCount)}
                </div>
            </div>
        </div>
    )
}
