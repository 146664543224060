import type { ComponentPropsWithoutRef, KeyboardEvent, MouseEvent } from "react"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { renderToStaticMarkup } from "react-dom/server"
import "./TagPagination.scss"
import { Chevron, Line } from "../../../common/atoms/Icons/Others"

const i18n = {
    of: (currentPage: number, maxPage: number): string => {
        return interpolate(
            gettext(
                "<div class='TagPagination__paging-text'>%(currentPage)s</div> <div class='TagPagination__paging-text'>of</div> <div class='TagPagination__paging-text'>%(maxPage)s</div>",
            ),
            { currentPage: currentPage, maxPage: maxPage },
            true,
        )
    },
    next: "next",
}

interface TagPaginationProps extends ComponentPropsWithoutRef<"div"> {
    isDisabled: boolean
    currentPage: number
    maxPage: number
    onNextPageClick: (e: MouseEvent | KeyboardEvent) => void
    onPrevPageClick: (e: MouseEvent | KeyboardEvent) => void
}

export function TagPagination({
    isDisabled,
    currentPage,
    maxPage,
    onNextPageClick,
    onPrevPageClick,
    ...props
}: TagPaginationProps) {
    const createPageNumberDisplayMarkUp = () => {
        return {
            __html: i18n.of(currentPage, maxPage),
        }
    }

    const handlePrevKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onPrevPageClick(event)
        }
    }

    const handleNextKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onNextPageClick(event)
        }
    }

    return (
        <div
            {...props}
            className={`TagPagination${
                isDisabled ? " TagPagination--disabled" : ""
            }`}
        >
            <div
                className="TagPagination__left-arrow"
                tabIndex={0}
                onClick={onPrevPageClick}
                onKeyDown={handlePrevKeyPress}
            >
                <Chevron className="TagPagination__arrow-icon" />
                <Line className="TagPagination__chevron-underline" />
            </div>

            <div
                className="TagPagination__paging-display wide"
                dangerouslySetInnerHTML={createPageNumberDisplayMarkUp()}
            />
            <div className="TagPagination__paging-display narrow">
                <div className="TagPagination__paging-text">
                    {`${currentPage} / ${maxPage}`}
                </div>
            </div>
            <div
                className="TagPagination__page-button"
                onClick={onNextPageClick}
                onKeyDown={handleNextKeyPress}
                title={i18n.next}
            >
                <div
                    className="TagPagination__paging-text TagPagination__paging-text--next"
                    tabIndex={0}
                >
                    {i18n.next}
                </div>
                <Chevron className="TagPagination__arrow-icon TagPagination__arrow-icon--next" />
                <Line className="TagPagination__chevron-underline" />
            </div>
        </div>
    )
}
