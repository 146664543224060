import { useEffect, useRef } from "react"
import type { ReactNode, SyntheticEvent } from "react"
import { Trans } from "@lingui/macro"
import { gettext } from "@multimediallc/web-utils"
import { useAppContext, useFeatureFlag } from "../../../hooks/appContext"
import { useAlert } from "../../../hooks/useAlert"
import { colorClass } from "../../../utils/css"
import {
    ButtonColor,
    ButtonsFloatDirection,
    CancelConfirmButtonPair,
    Link,
} from "../../common"
import { AlertType } from "../../common/organisms/Alert"
import { sendReport } from "./api"
import type { ISendReportResponse } from "./api"
import "./AbuseReportForm.scss"

export const i18n = {
    reportAbuseCatUnderage: gettext("Broadcaster is underage"),
    reportAbuseCatAdvertising: gettext("Broadcaster is advertising"),
    reportAbuseCatAbusive: gettext("Broadcaster is abusive"),
    reportAbuseCatIntoxicated: gettext("Broadcaster is intoxicated"),
    reportAbuseCatLarge: gettext("Using a toy that is too large"),
    reportAbuseCatOffline: gettext("Asking for offline payments"),
    reportAbuseCatPublic: gettext("Broadcasting in public"),
    reportAbuseCatUniform: gettext("Broadcasting in service uniform"),
    reportAbuseCatSleeping: gettext("Broadcaster is sleeping"),
    reportAbuseCatGender: gettext("Broadcaster is wrong gender"),
    reportAbuseCatOther: gettext("Other"),
    reportAbuseCategoryRequired: gettext("Please choose a category"),
    reportAbuseAdditionalComments: gettext("Additional comments:"),
    reportAbuseDescriptionRequired: gettext("Please add a description"),
    reportAbuseChooseCategory: gettext("Please choose a category."),
    reportAbuseAddDescription: gettext(
        "Please add a description of the abuse being reported.",
    ),
    reportAbuseSubmitted: gettext("Your report has been submitted."),
    reportAbuseTextTerms: gettext(
        "Your room report will be reviewed as soon as practicable.",
    ),
    reportAbuseTermsLink: gettext("Terms"),
    chooseCategoryText: gettext("Choose a category"),
    reportCAPS: gettext("REPORT"),
    cancelCAPS: gettext("CANCEL"),
}

type CategoriesObject = Record<string, string>

const CATEGORIES: CategoriesObject = {
    "---": "---",
    underage: i18n.reportAbuseCatUnderage,
    advertising: i18n.reportAbuseCatAdvertising,
    abusive: i18n.reportAbuseCatAbusive,
    intoxicated: i18n.reportAbuseCatIntoxicated,
    "large toy": i18n.reportAbuseCatLarge,
    "offline payments": i18n.reportAbuseCatOffline,
    "public broadcast": i18n.reportAbuseCatPublic,
    "service uniform": i18n.reportAbuseCatUniform,
    sleeping: i18n.reportAbuseCatSleeping,
    gender: i18n.reportAbuseCatGender,
    other: i18n.reportAbuseCatOther,
}

type AbuseReportFormProps = {
    closeModal: () => void
    roomName: string
}

export function AbuseReportForm(props: AbuseReportFormProps) {
    const categoryInput = useRef<HTMLSelectElement>(null)
    const commentInput = useRef<HTMLTextAreaElement>(null)
    const addAlert = useAlert()
    const languageCode = useAppContext().context.language_code
    const isLinguiActive = useFeatureFlag("LinguiJSReact").active

    useEffect(() => {
        categoryInput.current?.focus()
    }, [])

    const showAlert = (text: ReactNode) => {
        addAlert({
            type: AlertType.alert,
            title: "",
            contents: text,
        })
    }

    const resetAndCloseModal = () => {
        if (categoryInput.current !== null) {
            categoryInput.current.value = CATEGORIES["---"]
        }
        if (commentInput.current !== null) {
            commentInput.current.value = ""
        }
        props.closeModal()
    }

    const submitClickHandler = (event: SyntheticEvent) => {
        event.stopPropagation()

        const categoryValue = categoryInput.current?.value || ""
        const commentValue = commentInput.current?.value || ""

        if (categoryValue === "---") {
            showAlert(i18n.reportAbuseChooseCategory)
            return
        }
        if (categoryValue === "other" && commentValue === "") {
            showAlert(i18n.reportAbuseAddDescription)
            return
        }
        // Current behavior in offline rooms is to pretend that report submission is successful.
        if (props.roomName === "") {
            showAlert(i18n.reportAbuseSubmitted)
            resetAndCloseModal()
            return
        }

        sendReport(languageCode, props.roomName, categoryValue, commentValue)
            .then((response: ISendReportResponse) => {
                if (!response.success) {
                    if (response.html !== undefined) {
                        showAlert(
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: response.html,
                                }}
                            />,
                        )
                        resetAndCloseModal()
                    }
                } else {
                    showAlert(i18n.reportAbuseSubmitted)
                    resetAndCloseModal()
                }
            })
            .catch((err: Error) => {
                showAlert(err.message)
                resetAndCloseModal()
            })
    }

    const cancelClickHandler = (event: SyntheticEvent) => {
        event.stopPropagation()
        props.closeModal()
    }

    const categoryOptions = Object.keys(CATEGORIES).map((key: string) => {
        const text: string = CATEGORIES[key]
        return (
            <option value={key} key={key}>
                {text}
            </option>
        )
    })

    return (
        <div id="AbuseReport">
            <form>
                <div className={`categoryDiv ${colorClass.defaultColor}`}>
                    <label htmlFor="abuse-category">
                        {i18n.chooseCategoryText}
                    </label>
                    <select
                        id="abuse-category"
                        className="abuseInput"
                        ref={categoryInput}
                        data-testid="AbuseReportForm-category-input"
                    >
                        {categoryOptions}
                    </select>
                </div>
                <div className="commentsDiv">
                    <label htmlFor="abuse-comments">
                        {i18n.reportAbuseAdditionalComments}
                    </label>
                    <textarea
                        className="abuseInput"
                        id="abuse-comments"
                        data-testid="abuse-comments"
                        ref={commentInput}
                        maxLength={255}
                    />
                </div>

                {isLinguiActive ? (
                    <div className="abuseTermsDiv" data-testid="abuse-terms">
                        <Trans>
                            Your room report will be reviewed as soon as
                            practicable.
                            <Link href="/terms/#room-reports">Terms</Link>
                        </Trans>
                    </div>
                ) : (
                    <div className="abuseTermsDiv" data-testid="abuse-terms">
                        {i18n.reportAbuseTextTerms}{" "}
                        <Link href="/terms/#room-reports">
                            {i18n.reportAbuseTermsLink}
                        </Link>
                    </div>
                )}
                <CancelConfirmButtonPair
                    cancelClickHandler={cancelClickHandler}
                    actionClickHandler={submitClickHandler}
                    actionText={i18n.reportCAPS}
                    cancelText={i18n.cancelCAPS}
                    actionColor={ButtonColor.Blue}
                    actionTestId="AbuseReportForm-submit-button"
                    floatDirection={ButtonsFloatDirection.Right}
                />
            </form>
        </div>
    )
}
